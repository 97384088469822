var exports = {};

(function (global, factory) {
  factory(exports);
})(exports, function (exports) {
  'use strict';

  var fp = typeof window !== "undefined" && window.flatpickr !== undefined ? window.flatpickr : {
    l10ns: {}
  };
  var Portuguese = {
    weekdays: {
      shorthand: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "S\xE1b"],
      longhand: ["Domingo", "Segunda-feira", "Ter\xE7a-feira", "Quarta-feira", "Quinta-feira", "Sexta-feira", "S\xE1bado"]
    },
    months: {
      shorthand: ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun", "Jul", "Ago", "Set", "Out", "Nov", "Dez"],
      longhand: ["Janeiro", "Fevereiro", "Mar\xE7o", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"]
    },
    rangeSeparator: " at\xE9 ",
    time_24hr: true
  };
  fp.l10ns.pt = Portuguese;
  var pt = fp.l10ns;
  exports.Portuguese = Portuguese;
  exports.default = pt;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
});

export default exports;
export const Portuguese = exports.Portuguese,
      __esModule = exports.__esModule;